<template>
  <div
    v-if="shouldRenderOnboarding && contactContentData && onboardingContentData"
    class="modal modal-onboarding is-active"
  >
    <div
      id="modalOnboardingContainer"
      class="modal-onboarding-container container is-max-desktop"
      data-track-content
    >
      <article v-if="isSupported === false" class="modal-onboarding-content-not-supported content">
        <header class="modal-onboarding-header">
          <img alt="WorkSafe logo" class="logo" src="../assets/worksafe_logo_onWhite.svg" />
          Return to Work Planner
        </header>
        <div class="notification is-warning" role="alert">
          "{{ injury.title }}" claims are not yet supported by this online tool.
        </div>
        <p>Here are some ways to get support.</p>
        <section>
          <h2 class="is-size-6">Contact your agent</h2>
          <div
            style="background-image: url('img/hero.jpg'); background-position: top right;"
            class="media is-hidden-mobile"
            aria-hidden
          ></div>
          <p>Select your agent to get their contact details below.</p>
          <div class="select">
            <select v-model="agent" v-if="agentsContentData">
              <option disabled value="">Select your agent</option>
              <option
                v-for="(a, key) in agentsContentData"
                :value="key"
                :key="`agent-${key}`"
              >
                {{ a.fullName }}
              </option>
            </select>
          </div>
          <article v-if="agent" class="notification notification-agent">
            <a
              :href="`tel:${agentsContentData[agent]['phone']}`"
              :title="`Call ${agentsContentData[agent]['phone']}`"
              class="button is-primary is-fullwidth has-text-weight-bold"
            >
              Call {{ agentsContentData[agent]['phone'] }}
            </a>
          </article>
        </section>
        <hr />
        <section>
          <h2 class="is-size-6">Get the WorkWell toolkit</h2>
          <p>A free, step-by-step online tool to help business leaders to promote a mentally healthy workplace.</p>
          <p><a href="https://www.workwell.vic.gov.au/toolkit" target="_blank" class="has-font-weight-bold">Learn more</a></p>
        </section>
        <hr />
        <section>
          <h2 class="is-size-6">Visit the WorkSafe website</h2>
          <p>Read helpful information on the Return to Work section on our website.</p>
          <p><a href="https://www.worksafe.vic.gov.au/return-to-work" target="_blank" class="has-font-weight-bold">Learn more</a></p>
        </section>
      </article>
      <article v-else class="modal-onboarding-layout">
        <header class="modal-onboarding-header">
          <img alt="WorkSafe logo" class="logo" src="../assets/worksafe_logo_onWhite.svg" />
          Return to Work Planner
        </header>
        <div
          v-show="shouldRenderErrorMessage"
          id="notificationError"
          class="notification is-danger"
          role="alert"
        >Please select an option below</div>
        <div
          :class="`content-step-${step + 1} modal-onboarding-content`"
          data-content-name="Onboarding content"
          :data-content-piece="`Onboarding step ${step + 1}: ${onboardingStepContent.title}`"
        >
          <h2
            v-if="onboardingStepContent.title"
            v-html="onboardingStepContent.title"
            class="modal-onboarding-content__title"></h2>
          <div
            v-if="onboardingStepContent.media"
            :style="{
              'background-image': `url('${onboardingStepContent.media.img}')`,
              'background-position': onboardingStepContent.media.backgroundPosition || 'center center'
            }"
            :class="`media${onboardingStepContent.media.isHiddenMobile ? ' is-hidden-mobile' : ''}`"
          >
            <video
              v-if="onboardingStepContent.media.video" 
              :key="onboardingStepContent.media.video"
              autoplay
              class="video"
              loop
              muted
            >
              <source :src="`${onboardingStepContent.media.video}.webm`" type="video/webm" />
              <source :src="`${onboardingStepContent.media.video}.mp4`" type="video/mp4" />
            </video>
          </div>
          <div
            v-if="onboardingStepContent.body"
            v-html="onboardingStepContent.body"
            class="modal-onboarding-content__body"></div>
          <aside
            v-if="onboardingStepContent.footnote"
            class="footnote"
          >{{ onboardingStepContent.footnote }}</aside>
          <div v-if="onboardingStepContent.questions">
            <ul
              v-if="Array.isArray(onboardingStepContent.questions)"
              class="modal-onboarding-questions"
            >
              <li
                v-for="(question, index) in onboardingStepContent.questions"
                :key="`onboardingStepContent-${question.id}`"
                class="modal-onboarding-question"
              >
                <label
                  :for="`onboardingStepContent-question-${question.id}`"
                  :class="`
                    modal-onboarding-question-label
                    ${index === 0 ? ' is-first' : ''}
                    ${index === (onboardingStepContent.questions.length - 1) ? ' is-last' : ''}
                    ${injury.id === question.id ? ' is-selected' : ''}
                  `"
                >
                  <input
                    :id="`onboardingStepContent-question-${question.id}`"
                    :value="question"
                    v-model="injury"
                    class="is-sr-only"
                    type="radio"
                  />
                  <p class="has-text-weight-bold">{{ question.title }}</p>
                  <p
                    v-if="question.subtitle"
                    v-html="question.subtitle"
                    class="is-size-7 is-grey-dark"
                  ></p>
                </label>
                <button
                  v-if="question.helpText"
                  :title="`Read more about ${question.title}`"
                  @click="showHelpTextModal(question)"
                  class="modal-onboarding-question-subtext"
                >?</button>
              </li>
            </ul>
            <ul 
              v-else-if="onboardingStepContent.questions === 'agents'"
              class="modal-onboarding-questions"
            >
              <li
                v-for="(a, key, index) in agentsContentData"
                :key="`onboardingStepContent-question-${key}`"
                class="modal-onboarding-question"
              >
                <label
                  :for="`onboardingStepContent-question-${key}`"
                  @click="handleEmployerData(key)"
                  :class="`modal-onboarding-question-label${index === 0 ? ' is-first' : ''}${key === agent ? ' is-selected' : ''}`"
                >
                  <input
                    :id="`onboardingStepContent-question-${key}`"
                    :value="key"
                    v-model="agent"
                    class="is-sr-only"
                    type="radio"
                  />
                  <span class="has-text-weight-bold">{{ a.fullName }}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="buttons">
          <button
            @click="nextStep"
            v-show="step < maxSteps - 1"
            class="button is-primary is-fullwidth has-text-weight-bold"
            :data-content-target="`onboardingSlide${step + 2}`"
          >Next</button>
          <button
            v-if="step === maxSteps - 1"
            @click="goToContactNow"
            class="button-contact-now button is-primary is-fullwidth"
          >
            <span>Step 1:</span>&nbsp;<strong>Contact your employee</strong>
          </button>
          <button 
            v-if="step === maxSteps - 1"
            @click="skipContactNow"
            class="button-skip-contact-now button is-ghost is-fullwidth"
          >I've already contacted my employee</button>
        </div>
      </article>
    </div>
    <div :class="`modal modal-help-text${helpTextModal.isHelpTextModalActive ? ' is-active' : ''}`">
      <div
        @click="hideHelpTextModal"
        class="modal-background"
        aria-hidden
      ></div>
      <div class="modal-card">
        <header class="modal-card-head">{{ helpTextModal.title }}</header>
        <section class="modal-card-body">
          <div v-html="helpTextModal.helpText" class="content"></div>
        </section>
        <div class="modal-card-foot">
          <button @click="hideHelpTextModal" class="button">Close window</button>
        </div>
      </div>
      <button
        @click="hideHelpTextModal"
        class="modal-close is-large"
        aria-label="close"
      ></button>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    // Ok, I admit the code here is pretty 💩 but this was my last week and I had to deploy now so I can test the feature
    // Any questions, please refer to the README.md or my handover docs https://docs.google.com/document/d/1ehqmKEjMtawBwUKI6CYQqyH4Va5gXV4XFENGof3CLS4/edit?usp=sharing
    name: 'TheOnboardingModal',
    data() {
      return {
        step: 0,
        injury: {},
        isSupported: undefined,
        agent: '',
        shouldRenderErrorMessage: false,
        helpTextModal: {
          title: '',
          helpText: '',
          isHelpTextModalActive: false,
        }
      }
    },
    methods: {
      nextStep() {
        const { questions } = this.onboardingStepContent
        this.setIsSupported(undefined)
        if (questions) {
          if (this.isQuestionValidated(questions)) {
            if (this.hasInjuryQuestions(questions)) this.setIsSupported(this.injury.isSupported)
            this.goToNextStep()
          } else {
            this.shouldRenderErrorMessage = true
            this.scrollToErrorNotification()
          }
        } else {
          this.goToNextStep()
        }
      },
      isQuestionValidated(questions) {
        if (this.hasInjuryQuestions(questions)) {
          if (!this.injury.id) return false
          if (!questions.some(q => q.id === this.injury.id)) return false
          return true
        } else if (questions === 'agents') {
          return !!this.agent
        } else {
          return false
        }
      },
      hasInjuryQuestions(questions) {
        return Array.isArray(questions)
      },
      setIsSupported(isSupported) {
        this.isSupported = isSupported
      },
      scrollToErrorNotification() {
        const posTop = document.getElementById('notificationError').offsetTop
        document.getElementById('modalOnboardingContainer').scrollTop = posTop
      },
      goToNextStep() {
        this.shouldRenderErrorMessage = false
        if (this.step < this.maxSteps - 1) {
          this.step++
        }
        document.getElementById('modalOnboardingContainer').scrollTop = 0
      },
      handleEmployerData(agentId) {
        this.$store.dispatch('employer/updateEmployerData', {
          'Current Agent': agentId
        })
      },
      completeOnboarding() {
        this.$store.dispatch('tasks/completeOnboarding')
      },
      goToContactNow() {
        this.completeOnboarding()
        const parentRouteName = this.contactContentData.routeName
        const subtaskRouteName = this.contactContentData?.subtasks[0]?.routeName
        if (parentRouteName && subtaskRouteName) {
          this.$router.push({ path: `/${parentRouteName}/${subtaskRouteName}` })
        }
      },
      skipContactNow() {
        this.completeOnboarding()
        const subtaskData = this.contactContentData?.subtasks[0]
        if (subtaskData) {
          this.$store.dispatch('tasks/toggleSubtaskCompleted', {
            id: subtaskData.routeName,
            isCompleted: true,
          })
        }
        this.$router.push('/')
      },
      showHelpTextModal(question) {
        if (question.helpText) {
          this.helpTextModal.title = question.title
          this.helpTextModal.helpText = question.helpText
          this.helpTextModal.isHelpTextModalActive = true
        }
      },
      hideHelpTextModal() {
        this.helpTextModal.title = ''
        this.helpTextModal.helpText = ''
        this.helpTextModal.isHelpTextModalActive = false
      }
    },
    computed: {
      ...mapGetters('auth', [ 'getAuthData' ]),
      ...mapGetters('tasks', [ 'shouldRenderOnboarding' ]),
      onboardingContentData() {
        const onboarding = this.$store.state?.content.contentData?.onboarding
        const isLoggedIn = !!(this.getAuthData.token)
        return isLoggedIn ? onboarding.filter(element => !element?.hidden_auth) : onboarding
      },
      maxSteps() {
        return this.onboardingContentData.length
      },
      onboardingStepContent() {
        return this.onboardingContentData[this.step]
      },
      contactContentData() {
        return this.$store.state?.content.contentData?.tasks?.[0]
      },
      agentsContentData() {
        return this.$store.state?.content.contentData?.agents
      },
    },
    created() {
      this.$store.dispatch('tasks/initOnboarding')
    },
  }
</script>
<style lang="scss" scoped>
  @import "../assets/variables";
  @import "~bulma/sass/utilities/initial-variables.sass";
  .modal-onboarding {
    background: $white;
    padding: 0;
  }
  .modal-onboarding-container {
    overflow-y: scroll;
    width: 100%;
    z-index: 50;
    @media (min-width: $tablet) {
      display: flex;
      flex-direction: row;
    }
  }
  .modal-onboarding-header {
    align-items: center;
    display: flex;
    font-weight: bold;
    margin-bottom: 1.5rem;
    .logo {
      height: 55px;
      margin-right: 16px;
      width: 115px;
    }
  }
  .modal-onboarding-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;
    @media (min-width: $tablet) {
      min-height: initial;
      width: calc(50% - 16px);
    }
  }
  .modal-onboarding-content {
    flex-grow: 1;
    @media (min-width: $tablet) {
      flex-grow: initial;
    }
  }
  .modal-onboarding-content__title {
    color: $blue;
    font-size: $size-4;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .modal-onboarding-content__body {
    font-size: $size-6;
    margin-bottom: 2rem;
  }
  .modal-onboarding-content-not-supported {
    padding: 20px;
    @media (min-width: $tablet) {
      width: calc(50% - 16px);
    }
  }
  .media {
    background-color: $white;
    background-size: cover;
    border-radius: $radius-large;
    height: 240px;
    margin-bottom: 1rem;
    @media (min-width: $tablet) {
      height: calc(100% - 40px);
      margin: 20px;
      position: fixed;
      right: 0;
      top: 0;
      width: calc(50% - 40px);
    }
  }
  .video {
    background-color: $white;
    max-height: 100%;
    width: 100%;
  }
  .modal-onboarding-questions {
    margin-bottom: 2rem;
    margin-left: -20px;
    margin-right: -20px;
    @media (min-width: $tablet) {
      border: 1px solid $grey-light;
      border-radius: $radius-large;
      margin-left: 0;
      margin-right: 0;
      overflow: hidden;
    }
  }
  .modal-onboarding-question {
    position: relative;
  }
  .modal-onboarding-question-label {
    cursor: pointer;
    display: block;
    border-bottom: 1px solid $grey-light;
    padding: 1rem 50px;
    position: relative;
    &:hover {
      background-color: $white-ter;
    }
    &.is-first {
      border-top: 1px solid $grey-light;
      @media (min-width: $tablet) {
        border-top: 0;
      }
    }
    &.is-last {
      border-bottom: 0;
    }
    &::before {
      border: 2px solid $grey-light;
      border-radius: 50%;
      content: "";
      display: block;
      height: 18px;
      left: 23px;
      position: absolute;
      top: calc(1rem + 3px);
      width: 18px;
    }
    &.is-selected::before {
      border-color: $blue;
    }
    &.is-selected::after {
      background: $black;
      border-radius: 50%;
      content: "";
      display: block;
      height: 8px;
      left: 28px;
      position: absolute;
      top: calc(1rem + 8px);
      width: 8px;
    }
  }
  .modal-onboarding-question-subtext {
    background: $grey-lighter;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    font-weight: bold;
    height: 24px;
    line-height: 24px;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 27px;
    width: 24px;
  }
  .icons-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
  }
  .icons-list-item {
    align-items: center;
    border: 1px solid $grey-lighter;
    border-radius: $radius-large;
    color: $red;
    display: flex;
    font-weight: $weight-bold;
    gap: .5rem;
    padding: 1rem;
  }
  .icons-list-item-img-frame {
    align-items: center;
    display: inline-flex;
    height: 3rem;
    justify-content: center;
    width: 3rem;
  }
  .icons-list-item-img {
    max-width: 100%;
  }
  .footnote {
    color: $grey-dark;
    font-size: $size-7;
    margin: 1rem 0;
    @media (min-width: $tablet) {
      grid-area: content;
    }
  }
  .buttons {
    padding: 1rem 0;
    justify-content: flex-end;
    @media (min-width: $tablet) {
      grid-area: content;
      grid-row: 2;
      padding: 0;
    }
    .button:last-child {
      margin-bottom: 20px;
    }
  }
  .button-skip-contact-now {
    &.is-ghost {
      text-decoration: underline;
    }
  }
  .notification-agent {
    margin-bottom: 1rem;
    margin-top: 1rem;
    .button {
      text-decoration: none;
    }
  }
  .modal-help-text {
    z-index: 100;
    .modal-content {
      z-index: 110;
    }
    .modal-card-head {
      font-size: $size-5;
      font-weight: bold;
      padding-bottom: 0;
    }
    .modal-card-foot {
      .button {
        width: 100%;
        @media (min-width: $tablet) {
          width: auto;
        }
      }
    }
  }
</style>
