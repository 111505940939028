<template>
  <main class="main-container">
    <article class="Verify is-flex is-justify-content-center is-align-content-center">
      <h1 class="title is-size-3">Verifying&hellip;</h1>
    </article>
  </main>
</template>

<script>
  import store from '@/store/index'

  export default {
    name: 'VerifyPage',
    data() {
      return {
        i: this.$route.params.i,
        k: this.$route.params.k,
        store: store
      }
    },
    methods: {
      verifyParams() {
        const auth = store.getters["auth/isTokenActive"];
        const SCOPE = this;
        if (!auth) {
          let params = {
            i: this.i,
            k: this.k,
          }
          this.axios.post(`https://rtb1t3uqla.execute-api.ap-southeast-2.amazonaws.com/v1/auth/employer?i=${params.i}&k=${params.k}`, {})
            .then(function (response) {
              let employerData = JSON.parse(response.data.Attributes.DATA_OBJECT);

              SCOPE.store.commit('employer/setEmployerData', employerData);

              localStorage.setItem("i", params.i);
              localStorage.setItem("k", params.k);

              // Set the local tokens
              localStorage.setItem("access_token", response.headers.accesstoken);
              localStorage.setItem("refresh_token", response.headers.refreshtoken);

              // Go to dashboard
              SCOPE.$router.push("/");
            })
            .catch(function (err) {
              console.error('not happy with that request', err, params);
              // Go to error pages
              SCOPE.$router.push("/");
            })
        } else {
          // Hydrate from local storage , go to dashboard
          console.log("authed so hydrated from localStorage in appRouter");
          SCOPE.$router.push("/");
        }
      }
    },
    mounted() {
      this.verifyParams()
    }
  }
</script>
