const state = () => ({
  employerData: {},
})
  
const getters = {
  getEmployerData(){
    return JSON.parse(localStorage.getItem("employerData"))
  }
}

const actions = {
  updateEmployerData({ commit, getters }, data) {
    commit('setEmployerData', {
      ...getters.getEmployerData,
      ...data,
    })
  }
}
  
const mutations = {
  setEmployerData(state, data) {
    state.employerData = data
    localStorage.setItem("employerData", JSON.stringify(data))
  }
}
  
export default{
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
