const contentMixin = {
  methods: {
    contentFilter(content = '', shouldMask = true) {
      const patterns = [
        [ '[IW_FIRSTNAME]', this.iwFirstName || 'your injured worker' ],
        [ '[EMPLOYER_FIRSTNAME]', this.employerFirstName || 'employer' ],
        [ '[AGENT_NAME]', this.agentData.fullName || 'your Agent' ],
        [ '[AGENT_PHONE]', this.agentData.phone || `your Agent's phone number` ],
      ]
      const target = content[0] === '['
        ? `${content[0].toUpperCase()}${content.slice(1)}`
        : content
      return patterns.reduce((filtered, pattern) => {
        const [ substr, newSubstr ] = pattern
        return (
          filtered 
          && filtered.replaceAll(
            substr,
            shouldMask ? `<span data-matomo-mask>${newSubstr}</span>` : newSubstr
          )
        )
      }, target)
    },
    renderContent(contentData, shouldMask) {
      const isLoggedIn = !!(this.$store.getters['auth/getAuthData'].token)
      shouldMask = shouldMask ?? isLoggedIn
      if (typeof contentData === 'object') {
        if (isLoggedIn) {
          return this.contentFilter(contentData.auth, shouldMask)
        } else {
          return this.contentFilter(contentData.unauth, shouldMask)
        }
      }
      return this.contentFilter(contentData, shouldMask)
    },
  },
  computed: {
    contentObject() {
      return this.$store.state?.content.contentData
    },
  }
}

export default contentMixin
