const state = {
  taskData: [],
  showNextStepModal: false,
  isOnboarded: false,
}

export const actions = {
  initTaskData({ commit, dispatch }, contentData) {
    const localTaskData = JSON.parse(localStorage.getItem('taskData')) || []
    const taskData = contentData.tasks.reduce((previousTaskData, task) => (
      task?.subtasks?.length > 0 ?
        [ 
          ...previousTaskData,
          task.subtasks.reduce((previousSubtaskData, subtask) => (
            subtask?.routeName ?
              [ ...previousSubtaskData, {
                id: subtask.routeName,
                isCompleted: !!(
                  localTaskData.length
                  && localTaskData.find(task => task.id === subtask.routeName)?.isCompleted
                ),
              } ]
              : previousSubtaskData
          ), [])
        ]
        : previousTaskData
    ), []).flat()

    if (taskData) {
      commit('populateTaskData', taskData)

      if (hasLegacyTaskData()) {
        dispatch('tasks/migrateLegacyTaskData', null, { root: true })
      }
    }
  },

  migrateLegacyTaskData({ commit, state }) {
    const migration = new taskDataMigration(state, localStorage.getItem('contentData'))
    commit('setSubTaskCompleted', migration.getPayload())
  },

  toggleSubtaskCompleted({ commit }, subtaskData) {
    commit('setSubtaskCompleted', subtaskData)
  },

  renderNextStepModal({ commit }, isRendered) {
    commit('setNextStepModal', isRendered)
  },

  initOnboarding({ commit }) {
    const localIsOnboarded = !!(JSON.parse(localStorage.getItem('isOnboarded')))
    commit('setOnboarded', localIsOnboarded)
  },

  completeOnboarding({ commit }) {
    commit('setOnboarded', true)
  },
}

const getters = {
  all(state) {
    return state.taskData
  },

  getSubtaskDataById: state => id => {
    const { taskData } = state
    return taskData.find(subtask => subtask.id === id) || null
  },

  getNextSubtask: (state, getters) => currentTaskId => {
    const all = getters.all
    const currentIndex = all.findIndex(task => task.id === currentTaskId)
    const tasksAfter = all.slice(currentIndex + 1)
    return (
      tasksAfter.find(subtask => !subtask.isCompleted) 
      || all.slice(0, currentIndex).find(subtask => !subtask.isCompleted)
      || undefined
    )
  },

  getNextTaskContentData: (state, getters, rootState, rootGetters) => currentTaskId => {
    const nextSubtask = getters.getNextSubtask(currentTaskId)

    if (nextSubtask) {
      const tasks = JSON.parse(JSON.stringify(rootGetters['content/getContentData']?.tasks ?? '{}'))
      
      if (Object.keys(tasks).length > 0) for (const task of tasks) {
        const subtasks = task.subtasks
        if (subtasks?.length) for (const subtask of subtasks) {
          if (nextSubtask.id === subtask.routeName) {
            return {
              ...task,
              subtasks: [ { ...subtask } ]
            }
          }
        }
      }
    }

    return null
  },

  isAllCompleted(state, getters) {
    const all = getters.all
    return all.find(task => !task.isCompleted) === undefined
  },

  shouldRenderOnboarding(state) {
    const localIsOnboarded = JSON.parse(localStorage.getItem('isOnboarded'))
    return !state.isOnboarded && !localIsOnboarded
  }
}

export const mutations = {
  populateTaskData(state, taskData) {
    state.taskData = taskData
    localStorage.setItem('taskData', JSON.stringify(taskData))
  },

  setSubtaskCompleted(state, subtaskData) {
    const i = state.taskData.findIndex(subtask => subtask.id === subtaskData.id)

    if (i > -1) {
      state.taskData[i] = subtaskData
      localStorage.setItem('taskData', JSON.stringify(state.taskData))
    }
  },

  setNextStepModal(state, isRendered) {
    state.showNextStepModal = isRendered
  },

  setOnboarded(state, payload) {
    state.isOnboarded = payload
    localStorage.setItem('isOnboarded', payload)
  },
}

function taskDataMigration(state, incoming) {
  this._state = state
  this._incoming = JSON.parse(incoming).tasks
  this._getParentTasks = () => (
    this._state.taskData.reduce((previousData, task) => {
      const incomingTaskData = this._incoming.find(incomingTask => incomingTask.routeName === task.id)
      const subtaskData = task.subtasks && incomingTaskData && this._getSubtasks(task.subtasks, incomingTaskData.subtasks)
      return subtaskData?.length > 0 ?
        [ ...previousData, { id: task.id, subtasks: subtaskData } ]
        : previousData
    }, [])
  )
  this._getSubtasks = (
    (subtasks, incomingSubtaskData) => subtasks.reduce((previousData, subtask) => {
      const subtaskData = incomingSubtaskData.find(incomingSubtask => incomingSubtask.routeName === subtask.id)
      return subtaskData ? 
        [ ...previousData, { id: subtaskData.routeName, isCompleted: subtaskData.calculated_isComplete } ] 
        : previousData
    }, [])
  )
  this.getPayload = () => this._getParentTasks()
}

function hasLegacyTaskData() {  
  const parentTask = JSON.parse(localStorage.getItem('contentData'))?.tasks?.[0]
  if (parentTask?.calculated_isComplete || parentTask?.calculated_progressVal) {
    return true
  }

  const subtask = parentTask?.subtasks?.[0]
  if (subtask?.calculated_isComplete || subtask?.showContactBtn) {
    return true
  }

  return false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
