import axios from 'axios'
import hash from 'object-hash'

const actions = {
  async initContentData({ commit, dispatch }) {
    try {
      const contentData = await dispatch('fetchContentData')
      const contentHash = hash(contentData)
      commit('populateContentData', { contentData, contentHash })
      dispatch('tasks/initTaskData', contentData, { root: true })
    } catch(error) {
      console.log(error)
    }
  },
  async fetchContentData() {
    try {
      const resp = await axios.get('/data/content.en.json')
      return resp.data
    } catch(error) {
      console.log(error)
    }
  },
}

export default actions
