const getters = {
  isContentUpToDate: (state) => (contentHash = '') => {
    if (Object.keys(state.contentData).length === 0) return false
    if (!state.contentHash) return false

    if (contentHash === localStorage.getItem('contentHash')) { 
      return true
    }

    return false
  },

  getContentData(state) {
    return state.contentData
  },
}

export default getters
