const employerMixin = {
  computed: {
    employerData() {
      return this.$store.state && this.$store.state.employer.employerData
    },
    iwFirstName() {
      const iwName = this.employerData?.['IW Contact Name']
      return iwName && iwName.split(' ')[0]
    },
    employerFirstName() {
      const empName = this.employerData?.['Employer Contact Name']
      return empName && empName.split(' ')[0]
    },
    agentData() {
      const agentId = this.employerData?.['Current Agent']
      const agentData = this.contentObject?.agents?.[agentId]
      return {
        fullName: agentData?.fullName && agentId,
        phone: agentData?.phone,
      }
    },
  },
}

export default employerMixin
