<template>
  <div class="modal" :class="{ 'is-active': isActive }">
    <div @click="renderNextStepModal(false)" class="modal-background is-clickable"></div>
    <div class="modal-card">
      <header class="modal-card-head is-flex-direction-column is-align-items-center pb-0">
        <span aria-hidden class="icon mb-4">
          <i class="icon-checkmark"></i>
        </span>
        <h2 class="modal-card-title has-text-weight-bold">{{ content.title }}</h2>
      </header>
      <section class="modal-card-body">
        <p>{{ content.body }}</p>
      </section>
      <footer class="modal-card-foot pt-0">
        <next-step-buttons v-if="!isAllCompleted" :currentTaskId="currentTaskId"></next-step-buttons>
      </footer>
    </div>
    <button
      @click="renderNextStepModal(false)"
      aria-label="close"
      class="modal-close is-large"></button>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import NextStepButtons from '@/components/NextStepButtons'
  export default {
    name: 'AlertModal',
    components: { NextStepButtons },
    props: {
      currentTaskId: String,
      isActive: {
        type: Boolean,
        default: false,
      },
      isAllCompleted: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      ...mapActions('tasks', ['renderNextStepModal']),
    },
    computed: {
      content() {
        return !this.isAllCompleted ?
          {
            title: 'Task complete!',
            body: `Remember it's still your obligation to continue to check in with your employee. You can continue on to the next task or come back to this one at any point.`,
          }
          : 
          {
            title: 'All tasks complete',
            body: 'You have checked off all the tasks.',
          }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../assets/variables";
  @import "~bulma/sass/utilities/initial-variables.sass";

  .modal-card-title {
    color: $green-a11y;
  }

  .icon {
    background-color: $green;
    border-radius: $radius-rounded;
    color: $white;
    padding: $size-4;
  }

  .icon-checkmark {
    font-size: $size-3;
  }
</style>
