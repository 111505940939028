<template>
  <div class="button-group is-flex-grow-1">
    <div class="button-group__next-step">
      <router-link
        v-if="nextStepButton"
        @click="hideNextStepModal"
        :to="nextStepButton.to"
        :title="`Next step: ${nextStepButton.label}`"
        class="button button-next-step is-primary mb-3"
      >
        <span class="button-next-step__label">
          Next step:
          <strong class="ml-1" v-html="nextStepButton.label" data-matomo-mask></strong>
        </span>
      </router-link>
    </div>
    <div class="button-group__view-all">
      <router-link
        @click="hideNextStepModal"
        class="button is-outlined mb-3 pl-3"
        title="Return to homepage to view all tasks"
        to="/"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" aria-hidden>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#424b78" />
        </svg>
        <span class="ml-1">View all tasks</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import contentMixin from '@/mixins/contentMixin'
  import employerMixin from '@/mixins/employerMixin'
  export default {
    name: 'NextStepButtons',
    mixins: [ contentMixin, employerMixin ],
    props: {
      currentTaskId: String
    },
    methods: {
      ...mapActions('tasks', ['renderNextStepModal']),
      hideNextStepModal() {
        this.renderNextStepModal(false)
      }
    },
    computed: {
      ...mapGetters('tasks', ['getNextTaskContentData']),
      nextStepButton() {
        const nextTaskContentData = this.getNextTaskContentData(this.currentTaskId)
        if (nextTaskContentData) {
          const subtaskContentData = nextTaskContentData.subtasks[0]
          return {
            label: this.renderContent(subtaskContentData.title, false),
            to: `/${nextTaskContentData.routeName}/${subtaskContentData.routeName}`,
          }
        }
        return null
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../assets/variables";
  @import "~bulma/sass/utilities/initial-variables.sass";

  .button-group {
    @media (min-width: $tablet) {
      display: grid;
      grid-gap: $gap;
      grid-template-columns: auto minmax(0, 1fr);
    }

    &__view-all {
      @media (min-width: $tablet) {
        order: 0;
      }
    }

    &__next-step {
      justify-content: flex-end;
      display: flex;

      @media (min-width: $tablet) {
        order: 1;
      }
    }
  }
  .button {
    width: 100%;

    @media (min-width: $tablet) {
      width: auto;
    }
  }

  .button-next-step {
    max-width: 100%;

    &__label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
