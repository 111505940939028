const mtmMixin = {
  methods: {
    pushMtmEvent(eventName, key, value) {
      window._mtm = window._mtm || []
      window._mtm.push({ 'event': eventName, [key]: value })
    },
    trackVisibleContentImpressions(checkOnScroll = true, timeIntervalInMs = 750) {
      window._paq = window._paq || []
      window._paq.push(['trackVisibleContentImpressions', checkOnScroll, timeIntervalInMs])
    }
  },
}

export default mtmMixin
