<template>
  <div v-if="employerData" :class="completedBoxClass" class="task-panel block box has-text-left">
    <div @click="toggleAccordionState" class="is-flex is-justify-content-space-between is-align-items-center has-pointer mb-2">
      <p
        :class="textColourClass"
        class="task-panel__title has-text-left is-size-4 has-text-weight-bold is-width-90pc"
      >
        {{ index + 1 }}. <span v-html="renderContent(task.title)"></span>
      </p>
      <i :class="iconClass" class="icon-chevron-up has-text-black"></i>
    </div>
    <p
      v-if="task.subtitle"
      v-html="renderContent(task.subtitle)"
      :class="textColourClass"
      class="has-text-left is-size-6 is-hidden-touch mb-4"
    ></p>
    <ul v-if="accordionOpened && task.subtasks.length" class="mt-4">
      <li v-for="(subtask, index) in task.subtasks" :key="index" class="task-panel__subtask has-text-left mb-4">
        <router-link
          :to="`/${task.routeName}/${subtask.routeName}`"
          :class="getButtonColourClass(subtask)"
          :data-content-target="`/${task.routeName}/${subtask.routeName}`"
          class="task-panel__subtask-button button is-justify-content-space-between has-text-left has-text-weight-bold is-wrapping-button py-2"
        >
          <span v-html="renderContent(subtask.title)"></span>
          <div :class="getShowTickClass(subtask)"><i class="icon-checkmark ml-4"></i></div>
          <i v-if="getShowTickClass(subtask)" class="icon-chevron-right ml-4"></i>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import contentMixin from '@/mixins/contentMixin'
import employerMixin from '@/mixins/employerMixin'

export default {
  name: "TaskPanel",
  mixins: [ contentMixin, employerMixin ],
  props: {
    index: Number,
    task: Object,
  },
  data() {
    return {
      progressVal: 0,
      accordionOpened: true
    }
  },
  methods: {
    getButtonColourClass(subtask) {
      return subtask.isCompleted ? 'is-info  text-success-dark' : 'is-link  text-link-dark'
    },
    getShowTickClass(subtask) {
      return subtask.isCompleted ? '' : 'is-hidden'
    },
    toggleAccordionState() {
      this.accordionOpened = !this.accordionOpened
    },
    onTaskCompleted() {
      this.$emit('onTaskCompleted')
    }
  },
  computed: {
    ...mapState(['employer']),
    completedBoxClass() {
      return this.progressVal >= 100 ? 'box-completed' : ''
    },
    textColourClass() {
      return (this.progressVal >= 100) ? 'text-success-dark' : 'has-text-black';
    },
    iconClass() {
      let classes = (this.progressVal >= 100) ? 'text-success-dark' : 'has-text-black';
      if (this.accordionOpened) {
        classes += '  icon-chevron-up--rotated'
      }
      return classes
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  width: 100%;
}

li {
  width: 100%;

  a {
    width: 100%;
  }
}

.is-wrapping-button {
  white-space: pre-wrap !important;
  line-height: 1.2 !important;
  height: auto !important;
  word-break: break-word !important;
  
  .icon-checkmark {
    font-size: 1.75em;
  }
}

.is-width-90pc {
  @media (max-width: 1023px) {
    max-width: 90%;
  }
}
</style>