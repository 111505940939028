import { createStore } from "vuex"
import authModule from './modules/auth'
import contentModule from './modules/content'
import employerModule from './modules/employer'
import tasksModule from './modules/tasks'
 
const store = createStore({
  modules: {
    auth: authModule,
    content: contentModule,
    employer: employerModule,
    tasks: tasksModule,
  },
});

export default store
