<template>
  <main class="main-container">
    <article class="ErrorPage content has-text-left">
      <h1 class="title is-size-4">Please reload the Return to Work Planner</h1>
      <p>
        <router-link class="button is-link is-rounded has-text-white" type="button" to="/">
          Reload
        </router-link>
      </p>
    </article>
  </main>
</template>

<script>
export default {
  name: 'ReloadPage',
  data(){
    return {}
  }
}
</script>