<template>
  <div v-if="shouldRender" class="has-background-link content px-5 py-3 mb-0 is-fixed-bottom contact-panel">
    <div class="has-text-right">
      <a
        @click="onHideContactPanel"
        class="button-contact-panel-close"
      >&times;</a>
    </div>
    <div class="button-wrapper">
      <h3 class="title">Make contact now</h3>
      <a
        v-if="iwContactNumber"
        @click="pushMtmEvent('contactButton', 'contactButton', 'tel')"
        class="contact-panel-button-tel button is-white"
        :href="`tel:${iwContactNumber}`"
      >
        <i class="icon-phone mr-4"></i>
        Call&nbsp;<span data-matomo-mask>{{ iwContactNumber }}</span>
      </a>
      <a
        v-if="iwContactNumber"
        @click="pushMtmEvent('contactButton', 'contactButton', 'sms')"
        class="contact-panel-button-sms button is-white"
        :href="`sms:${iwContactNumber}`"
      >
        <i class="icon-mobile2 mr-4"></i>
        Text&nbsp;<span data-matomo-mask>{{ iwFirstName }}</span>
      </a>
      <a
        v-if="iwEmail"
        @click="pushMtmEvent('contactButton', 'contactButton', 'email')"
        class="contact-panel-button-email button is-white"
        :href="`mailto:${iwEmail}`"
      >
        <i class="icon-envelop mr-4"></i>
        Email&nbsp;<span data-matomo-mask>{{ iwEmail }}</span>
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import mtmEventMixin from '@/mixins/mtmEventMixin'

  export default {
    name: 'TheContactPanel',
    mixins: [ mtmEventMixin ],
    methods: {
      onHideContactPanel() {
        this.$emit('onHideContactPanel');
      },
    },
    computed: {
      ...mapGetters({
        employerData: 'employer/getEmployerData'
      }),
      iwFirstName() {
        const iwContactName = this.employerData?.['IW Contact Name']
        return iwContactName && iwContactName.split(" ")[0]
      },
      iwEmail() {
        return this.employerData?.['IW Contact Email']
      },
      iwContactNumber() {
        return this.employerData?.['IW Contact Number']
      },
      shouldRender() {
        return !!(this.iwEmail || this.iwContactNumber)
      }
    },
  }
</script>

<style scoped lang='scss'>
  @import "../assets/variables";
  @import "~bulma/sass/utilities/initial-variables.sass";

  .is-fixed-bottom {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    @media (min-width: 1024px) {
      min-height: 150px;
    }
  }

  .button-wrapper {
    text-align: left;

    @media (min-width: $desktop) {
      text-align: center;
    }
  }

  .button {
    font-weight: bold;
    justify-content: flex-start;
    margin-bottom: 1rem;
    text-decoration: none;
    width: 100%;

    @media (min-width: $desktop) {
      margin-right: 0.5em;
      text-align: center;
      width: auto;
    }

    &.is-white {
      color: $blue;
    }
  }

  .button-contact-panel-close {
    color: white;
    font-size: 32px;
    font-weight: bold;
    text-decoration: none;
  }

  .title {
    color: white;
  }
</style>