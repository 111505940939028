<template>
  <main class="main-container">
    <article v-if="contentObject && contentObject.navigation" class="content has-text-left">
      <h1 class="content-block--title is-size-3">{{renderContent(contentBlocks.title)}}</h1>
      <template v-if="contentBlocks.content">
        <section v-html="renderContent(contentBlocks.content)" class="content-block--content"></section>
        <hr />
      </template>
      <template v-if="contentBlocks.content2">
        <section v-html="renderContent(contentBlocks.content2)" class="content-block--content2"></section>
        <hr />
      </template>
      <section v-if="contentBlocks.content3" v-html="renderContent(contentBlocks.content3)" class="content-block--content3"></section>
    </article>
  </main>
</template>

<script>
import contentMixin from '@/mixins/contentMixin'
import employerMixin from '@/mixins/employerMixin'
import titleMixin from '@/mixins/titleMixin'

export default {
  name: 'BasicPage',
  mixins: [ contentMixin, employerMixin, titleMixin ],
  props: {
    pageName: String
  },
  computed: {
    contentBlocks () {
      return this.$store?.state && this.$store.state?.content?.contentData?.pages[this.pageName]
    },
  },
}
</script>

<style lang="scss">
  .is-fixed-bottom-right {
    position: fixed !important;
    right: 1.6em;
    bottom: 2.8em;
  }
</style>
