<template>
  <main class="main-container">
    <article class="ErrorPage content has-text-left">
      <h1 class="title is-size-4">The device you are using is not authorised to access the Return to Work Planner.</h1>
      <p>To access the Planner you must:</p>
      <ul>
        <li>Use the original email link</li>
      </ul>
    </article>
  </main>
</template>

<script>
export default {
  name: 'ErrorPage',
  data(){
    return {}
  }
}
</script>