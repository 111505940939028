const features = {
  unauth: {
    title: 'Unauthenticated view',
    isReleased: true,
  },
}

export const isFeatureReleased = (feature, featureList = features) => {
  const env = process?.env?.NODE_ENV
  
  if (env === 'development') return true
  if (env === 'production' && featureList?.[feature]?.isReleased) return true

  return false
}


export default features
