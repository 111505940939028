<template>
  <footer v-if="contentObject && contentObject.navigation" class="footer">
    <div class="columns mb-5">
        <div class="column is-6-desktop is-offset-3-desktop  has-text-left  px-2">
            <span class="has-text-white has-text-weight-bold">powered by </span>
            <img alt="WorkSafe logo" src="../assets/worksafe_logo.png" style="height: 48px; vertical-align:middle; padding-left:8px" >
        </div>
    </div>

    <div class="columns">
        <div class="column is-6-desktop is-offset-3-desktop">

          <div class="columns is-hidden-touch footerTableDesktop" v-show="showLinks">
            <div class="column"><span class="has-pointer" @click="goHome">{{contentObject.navigation.home}}</span></div>
            <div class="column"><span class="has-pointer" @click="goPage('faqs')">{{contentObject.navigation.faqs}}</span></div>
            <div class="column"><span class="has-pointer" @click="goPage('help')">{{contentObject.navigation.help}}</span></div>
            <div class="column"><span class="has-pointer" @click="goPage('privacy')">{{contentObject.navigation.privacy}}</span></div>
          </div>

          <div class="columns is-hidden-desktop is-multiline" v-show="showLinks">
            <div class="column is-full  footer-mobile-link-col">
              <a class="footer-mobile-link  px-0 py-0 is-flex is-justify-content-space-between" @click="goHome">
                <span class="has-text-weight-bold">{{contentObject.navigation.home}}</span>
                <i class="icon-chevron-right"></i>
              </a>
            </div>
            <div class="column is-full  footer-mobile-link-col">
              <a class="footer-mobile-link  px-0 py-0 is-flex is-justify-content-space-between" @click="goPage('faqs')">
                <span class="has-text-weight-bold">{{contentObject.navigation.faqs}}</span>
                <i class="icon-chevron-right"></i>
              </a>
            </div>
            <div class="column is-full  footer-mobile-link-col">
              <a class="footer-mobile-link  px-0 py-0 is-flex is-justify-content-space-between" @click="goPage('help')">
                <span class="has-text-weight-bold">{{contentObject.navigation.help}}</span>
                <i class="icon-chevron-right"></i>
              </a>
            </div>
            <div class="column is-full  footer-mobile-link-col">
              <a class="footer-mobile-link  px-0 py-0 is-flex is-justify-content-space-between" @click="goPage('privacy')">
                <span class="has-text-weight-bold">{{contentObject.navigation.privacy}}</span>
                <i class="icon-chevron-right"></i>
              </a>
            </div>
          </div>
          <div class="block mt-6"></div>
          <div class="is-flex is-justify-content-flex-start">
            <img src="../assets/flags/1.png" width="64" height="38" alt="flag-aboriginal" class="mr-4">
            <img src="../assets/flags/2.png" width="64" height="38" alt="flag-torres-strait-islander" class="mr-4">
            <img src="../assets/flags/3.png" width="64" height="38" alt="flag-lgbtiq" class="mr-4">
          </div>
          <div class="mt-5 has-text-white has-text-left" v-html="contentObject.pages.footer.acknowledgement"></div>
        </div>
    </div>  
  </footer>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: "TheFooterBar",
    methods: {
      goHome() {
        this.$router.push("/")
      },
      goPage(pageName) {
        this.$router.push(`/${pageName}`)
      }
    },
    computed: {
      ...mapState(['content']),
      contentObject() {
        return this.$store.state && this.$store.state.content.contentData
      },
      showLinks () {
        return  this.$router.currentRoute.value.fullPath !== '/error'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .footerTableDesktop {
    color: white;
    font-weight: bold;
    padding: 24px 0;
    border-top: .125rem solid hsla(0,0%,100%,.5);
    border-bottom: .125rem solid hsla(0,0%,100%,.5);
    margin-top: 12px !important;
  }

  .footer-mobile-link-col {
    border-bottom: 2px solid white;
    padding: 7px;

    &:first-of-type {
      border-top: 2px solid white;
    }

    .footer-mobile-link {
      color: #fff;
    }

    &:hover,
    &:focus {
      background: #000;
      .footer-mobile-link {
        color: #fdb813 !important;
      }
    }
  }
</style>