const titleMixin = {
  watch: {
    '$route': {
      handler(to) {
        document.title = to.meta.title || 'Return to Work Planner'
      },
      immediate: true,
    },
  },
  mounted() {
    const var_i = document.title.indexOf('[')
    if (var_i && document.title.includes(']', var_i)) {
      document.title = this.renderContent(document.title, false)
    }
  },
}

export default titleMixin
