const mutations = {
  populateContentData(state, { contentData, contentHash }) {
    state.contentData = contentData
    state.contentHash = contentHash

    if (contentHash !== localStorage.getItem('contentHash')) { 
      localStorage.setItem('contentData', JSON.stringify(contentData))
      localStorage.setItem('contentHash', contentHash)
    }
  },
}

export default mutations
