<template>
  <div>
    <the-header-bar @onShowContactPanel="toggleContactPanel"></the-header-bar>
    <router-view @onShowContactPanel="toggleContactPanel"></router-view>
    <the-contact-panel v-show="showContactPanel" @onHideContactPanel="toggleContactPanel"></the-contact-panel>
    <the-footer-bar></the-footer-bar>
  </div>
</template>

<script>
  import TheHeaderBar from './components/TheHeaderBar.vue'
  import TheFooterBar from './components/TheFooterBar.vue'
  import TheContactPanel from './components/TheContactPanel.vue'

  export default {
    name: 'App',
    data() {
      return {
        showContactPanel: false
      }
    },
    methods: { 
      toggleContactPanel() {
        this.showContactPanel = !this.showContactPanel
      },
    },
    components: {
      TheHeaderBar,
      TheFooterBar,
      TheContactPanel,
    },
  }
</script>

<style>
  @import './assets/main.scss';
</style>
