/// Credit for Auth: https://www.learmoreseekmore.com/2020/12/vue3-jwt-auth-accesstoken.html
import { createApp } from 'vue';
import * as appRouter from './appRouter';
import store from './store';
import http  from './http';
import features from './features'
import App from './App.vue';
require('@/assets/main.scss');

const app = createApp(App);

app
  .use(appRouter.routeConfig)
  .use(store)
  .use(http)
  .provide('features', features)
  .mount('#app');
