<template>
  <div>
    <div
      v-if="contentObject && contentObject.navigation"
      class="dashboard"
      data-track-content
      data-content-name="Dashboard"
    >
      <header v-if="heading || body" class="dashboard-intro hero has-text-left py-3">
        <article class="hero-body content">
          <h1 v-html="heading" class="dashboard-intro__heading is-size-4"></h1>
          <p v-html="body" class="dashboard-intro__body"></p>
        </article>
      </header>
      <main class="main-container">
        <task-panel
          v-for="(task, index) in taskPanelData"
          @onTaskCompleted="calculateTasksCompleted"
          :data-content-piece="`${index + 1}. ${task.title}`"
          :index="index"
          :key="task.routeName"
          :task="task"
        ></task-panel>
      </main>
    </div>
    <the-onboarding-modal></the-onboarding-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import contentMixin from '@/mixins/contentMixin'
import employerMixin from '@/mixins/employerMixin'
import mtmEventMixin from '@/mixins/mtmEventMixin'
import TaskPanel from '@/components/TaskPanel.vue'
import TheOnboardingModal from '@/components/TheOnboardingModal.vue'

export default {
  name: 'DashboardPage',
  mixins: [ contentMixin, employerMixin, mtmEventMixin ],
  methods: {
    calculateTasksCompleted() {
      let completedTasks = 0
      let totalTasks = this.contentObject.tasks.length
      for (let t in this.contentObject.tasks) {
          let completedSubtasks = 0
          let totalSubtasks = this.contentObject.tasks[t].subtasks.length
          for (let s in this.contentObject.tasks[t].subtasks) {
            if (this.contentObject.tasks[t].subtasks[s].calculated_isComplete) {
                completedSubtasks++;
            }
          }
          if (completedSubtasks === totalSubtasks) {
            completedTasks++
          }
      }
      if (completedTasks === totalTasks) {
        console.log("Congrats - you've done all the tasks")
        // TODO: Log Matomo Goal here.
      }
    },
  },
  components: {
    TaskPanel,
    TheOnboardingModal,
  },
  computed: {
    ...mapState([ 'content', 'employer', 'auth' ]),
    ...mapGetters([
      'content/getContentData',
      'tasks/all',
      'tasks/getSubtaskDataById',
    ]),
    authData() {
      return this.$store.state && this.$store.state.auth.authData
    },
    heading() {
      const heading = this.contentObject.pages.home.content.heading
      return heading && this.renderContent(heading)
    },
    body() {
      const body = this.contentObject.pages.home.content.body
      return body && this.renderContent(body)
    },
    taskPanelData() {
      const taskContentData = this['content/getContentData'].tasks
      const taskData = this['tasks/all']
      return taskContentData.map(
        parentTask => ({
          ...parentTask,
          subtasks: parentTask.subtasks.map(
            subtask => {
              const subtaskData = taskData.find(subtaskData => subtaskData.id === subtask.routeName)
              return { ...subtask, ...subtaskData }
            }
          ),
        })
      )
    }
  },
  created() {
    document.title = 'Return to Work Planner'
    this.trackVisibleContentImpressions()
  },
}
</script>
<style lang="scss" scoped>
  @import "../assets/variables";
  @import "~bulma/sass/utilities/initial-variables.sass";
  .dashboard-intro {
    background:
      linear-gradient(0deg, rgba(255, 255, 255, .75), rgba(255, 255, 255, .75)),
      url('/img/hero.jpg');
    background-position: right top;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    max-width: $widescreen;
    position: relative;
    @media (min-width: $widescreen) {
      border-radius: $radius-large;
      margin-top: 2.75rem;
    }
  }
  .hero-body {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-max-width;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    @media (min-width: $widescreen) {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem;
    }
  }
  .dashboard-intro__body {
    a {
      color: $black;
    }
  }
</style>
