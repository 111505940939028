<template>
  <nav class="navbar is-fixed-top is-dark" role="navigation" v-if="contentObject && contentObject.navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item has-pointer" style="z-index: 1" href="#" @click="goHome">
        <img alt="WorkSafe logo" src="../assets/worksafe_logo.png">
      </a>

      <a
        @click="toggleNav"
        v-show="showToggle"
        aria-expanded="false"
        aria-label="menu"
        class="navbar-burger"
        :class="navClass"
        data-target="navbarBasicExample"
        role="button"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>

    </div>

    <div class="app-title" @click="goHome" role="button"><strong class="has-text-white has-pointer">Return to Work Planner</strong></div>

    <div id="navbarBasicExample" class="navbar-menu navbar-menu--full-height" :class="navClass" >
      <div class="navbar-end is-hidden-touch" v-show="showToggle">
        <a class="navbar-item navbar-item-home" @click="goHome">
          <span class="has-text-weight-bold">{{contentObject.navigation.home}}</span>
        </a>
        <a class="navbar-item navbar-item-faqs" @click="goPage('faqs')">
          <span class="has-text-weight-bold">{{contentObject.navigation.faqs}}</span>
        </a>
        <a class="navbar-item navbar-item-help" @click="goPage('help')">
          <span class="has-text-weight-bold">{{contentObject.navigation.help}}</span>
        </a>        
        <a 
          v-if="shouldRenderContact"
          class="navbar-item navbar-item-contact"
          @click="toggleContactPanel"
        >
          <span v-html="renderContent(contentObject.navigation.contact)" class="has-text-weight-bold"></span>
        </a>
      </div>

      <div class="navbar-end px-4 mb-4 is-hidden-desktop">
        <a class="navbar-item navbar-item-home-touch px-0 is-flex is-justify-content-space-between" @click="goHome">
          <span class="has-text-weight-bold">{{contentObject.navigation.home}}</span>
          <i class="icon-chevron-right"></i>
        </a>
        <a class="navbar-item navbar-item-faqs-touch px-0  is-flex is-justify-content-space-between" @click="goPage('faqs')">
          <span class="has-text-weight-bold">{{contentObject.navigation.faqs}}</span>
          <i class="icon-chevron-right"></i>
        </a>
        <a class="navbar-item navbar-item-help-touch px-0 is-flex is-justify-content-space-between" @click="goPage('help')">
          <span class="has-text-weight-bold">{{contentObject.navigation.help}}</span>
          <i class="icon-chevron-right"></i>
        </a>
        <a
          v-if="shouldRenderContact" 
          @click="toggleContactPanel"
          class="navbar-item navbar-item-contact-touch px-0 is-flex is-justify-content-space-between"
        >
          <span class="has-text-weight-bold" v-html="renderContent(contentObject.navigation.contact)"></span>
          <i class="icon-chevron-right"></i>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
  import contentMixin from '@/mixins/contentMixin'
  import employerMixin from '@/mixins/employerMixin'
  import { mapGetters } from 'vuex'

  export default {
    name: 'TheHeaderBar',
    mixins: [ contentMixin, employerMixin ],
    data() {
      return {
        navActive: false
      }
    },
    methods: {
      toggleNav() {
        this.navActive = !this.navActive
      },
      toggleContactPanel () {
        this.$emit('onShowContactPanel')
      },
      goHome() {
        this.navActive = false
        window.scrollTo(0,0)
        this.$router.push("/")
      },
      goPage(pageName) {
        this.navActive = false
        this.$router.push(`/${pageName}`)
      }
    },
    computed: {
      ...mapGetters({
        employerData: 'employer/getEmployerData',
      }),
      navClass() {
        return this.navActive ? 'is-active' : ''
      },
      showToggle () {
        return this.$router.currentRoute.value.fullPath !== '/error'
      },
      shouldRenderContact() {
        return !!(
          this.employerData?.['IW Contact Number']
          || this.employerData?.['IW Contact Email']
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
  .app-title {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 0 165px;
    width: 50%;
    display: flex;
    height: 72px;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    font-size: 1.2rem;

    @media (max-width: 1023px) {
      text-align: center;
      width: calc(100% - 174px);
      height: 62px;
      font-size: 18px;
      left: 102px;
      padding: 0;
    }
  }

  .navbar-end {
    padding-right: 1rem;
  }

  .navbar-burger {
    height: 72px;
    transform: scale(1.6);
    position: relative;
    left: -6px;

    @media (max-width: 1023px) {
      height: 62px;
    }
  }

  .navbar.is-dark {
    background-color: #262626 !important;
  }

  .navbar-brand .navbar-item {
    cursor: pointer;
    margin: 0 1rem;
    min-height: 72px;
    
    @media (max-width: 1023px) {
      margin-left: 0;
      min-height: 62px;
    }
  }

  .navbar-item img {
    height: 55px;
    max-height: 55px;
    @media (max-width: 1023px) {
      height: 38px;
      max-height: 38px;
    }
  }

  .navbar-end .navbar-item {
    @media (max-width: 1023px) {
      &:first-of-type {
        border-top: 2px solid white;
      }
      border-bottom: 2px solid white;
      color: #fff;
      &:hover,
      :focus {
        background: #000;
        color: #fdb813 !important;
      }
    }
  }

  .navbar-menu--full-height {
    background: #262626;
    @media (max-width: 1023px) {
      height: 100vh;
    }
  }
</style>
