<template>
  <div class="modal modal-add-to-calendar is-active">
    <div
      @click="$emit('closeAddToCalender')"
      class="modal-background is-clickable"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" for="cal_title">Meeting title</label>
          </div>
          <div class="field-body">
            <div class="field is-normal">
              <div class="control is-expanded">
                <input
                  v-model="title"
                  class="input"
                  id="cal_title"
                  maxlength="256"
                  placeholder="Add meeting title"
                  type="text"
                  data-matomo-mask
                >
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" for="cal_startDate">When</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control is-expanded">
                <input 
                  v-model="startDate"
                  :min="minDate"
                  class="input"
                  id="cal_startDate"
                  placeholder="DD/MM/YYYY"
                  type="date"
                  data-matomo-mask
                >
              </div>
            </div>
            <div class="field">
              <label class="label is-sr-only" for="cal_startTime">Time</label>
              <div class="control is-expanded">
                <input
                  v-model="startTime"
                  class="input"
                  id="cal_startTime"
                  placeholder="HH:MM"
                  step="300"
                  type="time"
                  data-matomo-mask
                >
              </div>
              <p class="help">This is based on your local timezone</p>
            </div>
            <div class="field">
              <label class="label is-sr-only" for="cal-repeat">Repeat catch-up</label>
              <div class="select">
                <select id="cal-repeat" v-model="repeat" data-matomo-mask>
                  <option>Weekly</option>
                  <option selected>Fortnightly</option>
                  <option>Monthly</option>
                  <option>One-off</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal"><label class="label">Organiser</label></div>
          <div class="field-body">
            <div class="field">
              <label class="label is-sr-only" for="cal_organizer_name">Organiser name</label>
              <div class="control is-expanded">
                <input
                  v-model="organizer.name"
                  class="input"
                  id="cal_organizer_name"
                  placeholder="Add organiser name"
                  type="text"
                  data-matomo-mask
                >
              </div>
            </div>
            <div class="field">
              <label class="label is-sr-only" for="cal_organizer_email">Organiser email</label>
              <div class="control is-expanded">
                <input
                  v-model="organizer.email"
                  class="input"
                  id="cal_organizer_email"
                  placeholder="Add organiser email"
                  type="email"
                  data-matomo-mask
                >
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal"><label class="label">Employee</label></div>
          <div class="field-body">
            <div class="field">
              <label class="label is-sr-only" for="cal_attendee_name">Employee name</label>
              <div class="control is-expanded">
                <input
                  v-model="attendee.name"
                  class="input"
                  id="cal_attendee_name"
                  placeholder="Add employee name"
                  type="text"
                  data-matomo-mask
                >
              </div>
            </div>
            <div class="field">
              <label class="label is-sr-only" for="cal_attendee_email">Employee email</label>
              <div class="control is-expanded">
                <input
                  v-model="attendee.email"
                  class="input"
                  id="cal_attendee_email"
                  placeholder="Add employee email"
                  type="email"
                  data-matomo-mask
                >
              </div>    
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" for="cal_location">Location</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control is-expanded">
                <input
                  v-model="location"
                  class="input"
                  id="cal_location"
                  placeholder="Add location"
                  type="text"
                  data-matomo-mask
                >
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" for="cal_description">Message</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control is-expanded">
                <textarea
                  v-model="description"
                  class="textarea"
                  placeholder="Add messages or description about your regular check-in's"
                  id="cal_description"
                  data-matomo-mask
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="startDate && startTime" class="field is-horizontal">
          <div class="field-label is-normal"></div>
          <div class="field-body">
            <div class="buttons">
              <div class="control mr-2">
                <div class="dropdown is-up" :class="{ 'is-active': isDropdownVisible }">
                  <div class="dropdown-trigger">
                    <button
                      @click="toggleDropdown()"
                      aria-controls="dropdown-menu"
                      aria-haspopup="true"
                      class="button is-primary is-outlined has-text-weight-bold"
                    >
                      <span class="icon is-small">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path d="M6.66082 8.98211V14.7946L0.571533 13.9553V8.98211H6.66082ZM6.66082 2.34818V8.23211H0.571533V3.18747L6.66082 2.34818ZM15.4287 8.98211V16L7.33046 14.8839V8.98211H15.4287ZM15.4287 1.14282V8.23211H7.33046V2.25889L15.4287 1.14282Z" fill="#424b78" />
                        </svg>
                      </span>
                      <span>Outlook</span>
                      <span class="icon is-small">
                        <i class="icon-chevron-up" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                  <div
                    class="dropdown-menu"
                    id="dropdown-menu"
                    role="menu"
                  >
                    <div class="dropdown-content">
                      <a
                        @click="pushMtmEvent('addToCalendar', 'addToCalendar', 'Download Outlook ics')"
                        :href="icsData"
                        class="dropdown-item cal-outlook-ics"
                        download="event.ics"
                      >
                        Outlook
                      </a>
                      <a
                        @click="pushMtmEvent('addToCalendar', 'addToCalendar', 'Add to Outlook 365 calendar')"
                        :href="`https://outlook.office.com/calendar/0/deeplink/compose?${this.outlookParams}`"
                        class="dropdown-item cal-outlook-365"
                        target="_blank"
                      >
                        Outlook 365 (online)
                      </a>
                      <a
                        @click="pushMtmEvent('addToCalendar', 'addToCalendar', 'Add to Outlook web calendar')"
                        :href="`https://outlook.live.com/calendar/0/deeplink/compose?${this.outlookParams}`"
                        class="dropdown-item cal-outlook-web"
                        target="_blank"
                      >
                        Outlook.com (online)
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <a 
                @click="pushMtmEvent('addToCalendar', 'addToCalendar', 'Download Apple ics')"
                :href="icsData"
                class="cal-apple button is-primary is-outlined has-text-weight-bold"
                download="event.ics"
              >
                <span class="icon is-small">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14.1519 10.8482C13.9197 11.5923 13.5536 12.3363 13.0536 13.0804C12.2858 14.247 11.5209 14.8304 10.759 14.8304C10.4673 14.8304 10.0507 14.7351 9.509 14.5446C8.99709 14.3542 8.54769 14.2589 8.16078 14.2589C7.79769 14.2589 7.37507 14.3571 6.89293 14.5536C6.41078 14.756 6.01793 14.8571 5.71436 14.8571C4.80959 14.8571 3.91376 14.0863 3.02686 12.5446C2.15186 10.9911 1.71436 9.49405 1.71436 8.05357C1.71436 6.69643 2.05066 5.58333 2.72328 4.71429C3.3959 3.85714 4.24114 3.42857 5.259 3.42857C5.68757 3.42857 6.21436 3.51786 6.83936 3.69643C7.4584 3.875 7.86912 3.96429 8.0715 3.96429C8.33936 3.96429 8.76495 3.8631 9.34828 3.66071C9.95543 3.45833 10.4703 3.35714 10.8929 3.35714C11.6013 3.35714 12.2352 3.5506 12.7947 3.9375C13.1042 4.15179 13.4138 4.4494 13.7233 4.83036C13.253 5.22917 12.9138 5.58036 12.7054 5.88393C12.3185 6.44345 12.1251 7.05952 12.1251 7.73214C12.1251 8.47024 12.3304 9.13393 12.7411 9.72321C13.1519 10.3125 13.6221 10.6875 14.1519 10.8482ZM10.7947 0.375C10.7947 0.738095 10.7084 1.14286 10.5358 1.58929C10.3572 2.03571 10.0804 2.44643 9.70543 2.82143C9.384 3.14286 9.06257 3.35714 8.74114 3.46429C8.5209 3.52976 8.21138 3.58036 7.81257 3.61607C7.83043 2.72917 8.06257 1.96429 8.509 1.32143C8.94947 0.684524 9.69352 0.244048 10.7411 0C10.7471 0.0178571 10.7545 0.0505952 10.7635 0.0982143C10.7724 0.145833 10.7798 0.178571 10.7858 0.196429C10.7858 0.220238 10.7873 0.25 10.7902 0.285714C10.7932 0.321429 10.7947 0.35119 10.7947 0.375Z" fill="#424b78" />
                  </svg>
                </span>
                <span>Apple</span>
              </a>
              <a
                @click="pushMtmEvent('addToCalendar', 'addToCalendar', 'Add to Google calendar')"
                :href="googleUrl"
                class="cal-google button is-primary is-outlined has-text-weight-bold"
                target="_blank"
              >
                <span class="icon is-small">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M7.99997 7.01782H14.4732C14.5446 7.41663 14.5803 7.79758 14.5803 8.16068C14.5803 9.45235 14.3095 10.6056 13.7678 11.6205C13.2262 12.6354 12.4538 13.4285 11.4509 14C10.4479 14.5714 9.29758 14.8571 7.99997 14.8571C7.06544 14.8571 6.17556 14.677 5.33032 14.3169C4.48508 13.9568 3.75592 13.4702 3.14282 12.8571C2.52973 12.244 2.04312 11.5148 1.683 10.6696C1.32288 9.82437 1.14282 8.93449 1.14282 7.99996C1.14282 7.06544 1.32288 6.17556 1.683 5.33032C2.04312 4.48508 2.52973 3.75592 3.14282 3.14282C3.75592 2.52973 4.48508 2.04312 5.33032 1.683C6.17556 1.32288 7.06544 1.14282 7.99997 1.14282C9.78568 1.14282 11.3184 1.74104 12.5982 2.93747L10.7321 4.73211C9.99997 4.02377 9.08925 3.66961 7.99997 3.66961C7.23211 3.66961 6.52229 3.86306 5.8705 4.24996C5.21872 4.63687 4.70235 5.16217 4.32139 5.82586C3.94044 6.48955 3.74997 7.21425 3.74997 7.99996C3.74997 8.78568 3.94044 9.51038 4.32139 10.1741C4.70235 10.8378 5.21872 11.3631 5.8705 11.75C6.52229 12.1369 7.23211 12.3303 7.99997 12.3303C8.51782 12.3303 8.99401 12.2589 9.42854 12.116C9.86306 11.9732 10.2202 11.7946 10.5 11.5803C10.7797 11.366 11.0238 11.122 11.2321 10.8482C11.4404 10.5744 11.5937 10.3154 11.6919 10.0714C11.7901 9.82735 11.8571 9.5952 11.8928 9.37496H7.99997V7.01782Z" fill="#424b78" />
                  </svg>
                </span>
                <span>Google</span>
              </a>
              <a
                @click="pushMtmEvent('addToCalendar', 'addToCalendar', 'Add to Yahoo calendar')"
                :href="yahooUrl"
                class="cal-yahoo button is-primary is-outlined has-text-weight-bold"
                target="_blank"
              >
                <span class="icon is-small">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" aria-hidden>
                    <path d="M8.81239 8.54464L8.92847 14.8571C8.55942 14.7917 8.24692 14.7589 7.99097 14.7589C7.74692 14.7589 7.43442 14.7917 7.05347 14.8571L7.16954 8.54464C6.93144 8.13393 6.42995 7.25446 5.66507 5.90625C4.90019 4.55804 4.25585 3.44345 3.73204 2.5625C3.20823 1.68155 2.66954 0.827381 2.11597 0C2.4612 0.0892857 2.78263 0.133929 3.08025 0.133929C3.34216 0.133929 3.67251 0.0892857 4.07132 0C4.44632 0.660714 4.84364 1.34375 5.26329 2.04911C5.68293 2.75446 6.17995 3.57738 6.75436 4.51786C7.32876 5.45833 7.74097 6.13393 7.99097 6.54464C8.2112 6.18155 8.5371 5.65327 8.96864 4.95982C9.40019 4.26637 9.74989 3.70089 10.0178 3.26339C10.2856 2.82589 10.5981 2.30208 10.9553 1.69196C11.3124 1.08185 11.6308 0.517857 11.9106 0C12.232 0.0833333 12.5505 0.125 12.866 0.125C13.1993 0.125 13.5386 0.0833333 13.8838 0C13.7172 0.232143 13.5386 0.495536 13.3481 0.790179C13.1576 1.08482 13.0103 1.31845 12.9061 1.49107C12.802 1.66369 12.6338 1.9494 12.4017 2.34821C12.1695 2.74702 12.0237 2.99702 11.9642 3.09821C11.0951 4.5744 10.0445 6.38988 8.81239 8.54464Z" fill="#424b78" />
                  </svg>
                </span>
                <span>Yahoo</span>
              </a>
            </div>
          </div>
        </div>
        <aside v-else class="notification is-warning">
          <strong>Meeting date and time have not been specified.</strong>
          Please enter above the date and time you'd like to check-in with your injured worker do create a calendar event.
        </aside>
      </section>
    </div>
    <button
      @click="$emit('closeAddToCalender')"
      aria-label="close"
      class="modal-close is-large"></button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { DateTime } from 'luxon'
  import mtmEventMixin from '@/mixins/mtmEventMixin'
  export default {
    name: 'AddtoCalendar',
    mixins: [ mtmEventMixin ],
    data() {
      return {
        title: 'Return to work check-in',
        startDate: '',
        startTime: '',
        repeat: 'Fortnightly',
        organizer: {
          name: '',
          email: '',
        },
        attendee: {
          name: '',
          email: '',
        },
        location: '',
        description: `Hi, let's put in some time to check in on how you're doing and how I can best help you with your return to work.`,
        isDropdownVisible: false,
      }
    },
    emits: [ 'closeAddToCalender' ],
    methods: {
      setDefaultData() {
        this.startDate = this.defaultStartObj.toISODate(),
        this.startTime = this.defaultStartObj.toISOTime({ includeOffset: false, suppressSeconds: true, suppressMilliseconds: true }),
        this.organizer.name = this.employerData?.['Employer Contact Name'] ?? ''
        this.organizer.email = this.employerData?.['Employer Contact Email'] ?? ''
        this.attendee.name = this.employerData?.['IW Contact Name'] ?? ''
        this.attendee.email = this.employerData?.['IW Contact Email'] ?? ''
        this.description = `Hi ${this.employerData?.['IW Contact Name'].split(' ')[0] || ''}, let's put in some time to check in on how you're doing and how I can best help you with your return to work.`
      },
      getQueryString(queryParams) {
        return queryParams.reduce((accumulatedString, queryParam, i) => {
          const [ param, value ] = queryParam
          const queryParamString = value ? (
              `${param}=${encodeURIComponent(value)}` +
              (i < queryParams.length - 1 ? '&' : '')
            )
            : ''
          return accumulatedString + queryParamString
        }, '')
      },
      toggleDropdown() {
        this.isDropdownVisible = !this.isDropdownVisible
      }
    },
    computed: {
      ...mapGetters('employer', {
        employerData: 'getEmployerData',
      }),
      minDate() {
        return DateTime.now().toISODate()
      },
      defaultStartObj() {
        return DateTime
          .now()
          .plus({ days: 7 })
          .set({ hour: 10, minute: 0, seconds: 0, milliseconds: 0 })
      },
      dt() {
        if (this.startDate && this.startTime) {
          const startObj = DateTime.fromISO(`${this.startDate}T${this.startTime}`)
          const outputOpts = { format: 'basic', includeOffset: false, suppressMilliseconds: true }
          return {
            startObj,
            start: startObj.toISO(outputOpts),
            end: startObj.plus({ minutes: 30 }).toISO(outputOpts),
          }
        }
        return null
      },
      rRule() {
        if (this.dt) {
          const weekday = this.dt.startObj.weekdayShort.substring(0, 2).toUpperCase()
          switch (this.repeat) {
            case 'Fortnightly':
              return `RRULE:FREQ=WEEKLY;BYDAY=${weekday};INTERVAL=2`
            case 'Weekly':
              return `RRULE:FREQ=WEEKLY;BYDAY=${weekday};INTERVAL=1`
            case 'Monthly': {
              const nthWeek = Math.ceil(this.dt.day / 7)
              const setPos = nthWeek > 4 ? -1 : nthWeek
              return `RRULE:FREQ=MONTHLY;BYSETPOS=${setPos};BYDAY=${weekday};INTERVAL=1`
            }
            case 'One-off':
              return ''
          }
        }
        return ''
      },
      icsData() {
        if (this.dt) {
          return (
            'data:text/plain,' +
            encodeURIComponent(
              'BEGIN:VCALENDAR\n' +
                'VERSION:2.0\n' +
                'CALSCALE:GREGORIAN\n' +
                'BEGIN:VEVENT\n' +
                  `SUMMARY:${this.title}\n` +
                  `DTSTART:${this.dt.start}\n` +
                  `DTEND:${this.dt.end}\n` +
                  (this.rRule ? `${this.rRule}\n` : '') +
                  (this.description ? `DESCRIPTION:${this.description}\n` : '') +
                  (this.organizer.name ? this.organizer.email && `ORGANIZER;CN=${this.organizer.name}:mailto:${this.organizer.email}\n` : '') +
                  (this.attendee.name ? this.attendee.email && `ATTENDEE;RSVP=TRUE;ROLE=REQ-PARTICIPANT;CN=${this.attendee.name}:mailto:${this.attendee.email}\n` : '') +
                  (this.location ? `LOCATION:${this.location}\n` : '') +
                  'STATUS:CONFIRMED\n' +
                  'X-MICROSOFT-CDO-BUSYSTATUS:BUSY\n' +
                'END:VEVENT\n' +
              'END:VCALENDAR'
            )
          )
        }
        return ''
      },
      googleUrl() {
        if (this.dt) {
          // https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/google.md
          const queryParams = [
            [ 'action', 'TEMPLATE' ],
            [ 'text', this.title ],
            [ 'dates', `${this.dt.start}/${this.dt.end}` ],
            [ 'details', this.description ],
            [ 'add', this.attendee.email ],
            [ 'src', this.organizer.email ],
            [ 'recur', this.rRule ],
          ]
          return 'https://calendar.google.com/calendar/render?' + this.getQueryString(queryParams)
        }
        return ''
      },
      outlookParams() {
        if (this.dt) {
          // https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/outlook-web.md
          const queryParams = [
            [ 'path', '/calendar/action/compose' ],
            [ 'rru', 'addevent' ],
            [ 'startdt', this.dt.startObj.toISO({ includeOffset: false, suppressMilliseconds: true }) ],
            [ 'enddt', this.dt.startObj.plus({ minutes: 30 }).toISO({ includeOffset: false, suppressMilliseconds: true }) ],
            [ 'subject', this.title ],
            [ 'body', this.description ],
            [ 'location', this.location ],
            [ 'to', this.attendee.email ],
          ]
          return this.getQueryString(queryParams)
        }
        return ''
      },
      yahooUrl() {
        if (this.dt) {
          // https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/yahoo.md
          const queryParams = [
            [ 'v', '60' ],
            [ 'TITLE', this.title ],
            [ 'ST', this.dt.start ],
            [ 'ET', this.dt.end ],
            [ 'DESC', this.description ],
            [ 'in_loc', this.location ],
            [ 'inv_list', this.attendee.email ],
          ]
          return 'https://calendar.yahoo.com/?' + this.getQueryString(queryParams)
        }
        return ''
      },
    },
    created() {
      this.setDefaultData()
    },
  }
</script>
