import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import { isFeatureReleased } from './features';
import VerifyPage from './pages/VerifyPage.vue';
import ErrorPage from './pages/ErrorPage.vue';
import ReloadPage from './pages/ReloadPage.vue';
import DashboardPage from './pages/DashboardPage.vue';
import ContentPage from './pages/ContentPage.vue';
import BasicPage from './pages/BasicPage.vue';
import store from "./store/index";

let employerData;
let routes = [
  { 
    path: '/:i/:k', 
    component: VerifyPage, 
    meta: { requiredAuth: false }, 
  },
  { 
    path: '/', 
    name: 'Dashboard', 
    component: DashboardPage, 
    meta: { requiredAuth: isFeatureReleased('unauth') ? false : true },
  },
  { 
    path: '/faqs', 
    component: BasicPage, 
    meta: { 
      requiredAuth: false,
      title: 'Frequently asked questions - Return to Work Planner',
    }, 
    props: { pageName: 'faqs' } 
  },
  { 
    path: '/help', 
    component: BasicPage, 
    meta: { 
      requiredAuth: false,
      title: 'Help - Return to Work Planner',
    }, 
    props: { pageName: 'help' } 
  },
  { 
    path: '/privacy', 
    component: BasicPage, 
    meta: { 
      requiredAuth: false,
      title: 'Privacy - Return to Work Planner',
    }, 
    props: { pageName: 'privacy' } 
  },
  { 
    path: '/error', 
    name: 'Error', 
    component: ErrorPage, 
    meta: { requiredAuth: false }, 
    props: { pageName: 'error' } 
  },
  {
    path: "/:catchAll(.*)",
    name: 'ReloadPage',
    component: ReloadPage,
  }
];

export const routeConfig = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

routeConfig.beforeEach(async (to, from, next) => {
  if (!store.getters["auth/getAuthData"].token) {
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    employerData = localStorage.getItem("employerData");
    if (access_token) {
      const data = {
        access_token,
        refresh_token,
      };
      store.commit('auth/saveTokenData', data);
    }
    if (employerData) {
      store.commit('employer/setEmployerData', JSON.parse(employerData))
    }
  }

  let auth = store.getters["auth/isTokenActive"]
  // console.log(`auth ${auth}`)

  // Refresh token
  if (!auth) {
    const authData = store.getters["auth/getAuthData"];
    // console.log('authData', authData);
    if (authData.token) {
      const p = {
        a: authData.token,
        t: authData.refreshToken,
        i: localStorage.getItem('i'),
        k: localStorage.getItem('k'),
      };
      const refreshResponse =
        await
          axios
            .get(`https://rtb1t3uqla.execute-api.ap-southeast-2.amazonaws.com/v1/auth/employer?a=${p.a}&t=${p.t}&i=${p.i}&k=${p.k}`)
            .catch(error => console.error(error));
      if (refreshResponse) {
        let authTokenData = {}
        if (refreshResponse.headers.accesstoken) authTokenData.access_token = refreshResponse.headers.accesstoken
        if (refreshResponse.headers.refreshtoken) authTokenData.refresh_token = refreshResponse.headers.refreshtoken
        store.commit("auth/saveTokenData", authTokenData);
        auth = true;
      }
    } else {
      // Not signed in yet
    }
  }

  pushMtmUserId()

  await store.dispatch('content/initContentData')
  const contentData = JSON.parse(localStorage.getItem('contentData'))
  // Dynamically add the routes if they are not existing and pass through their pages content
  for (let t = 0; t < contentData.tasks.length; t++) {
    let parentTask = contentData.tasks[t]
    let parentRoute = parentTask.routeName
    for (let s = 0; s < contentData.tasks[t].subtasks.length; s++) {
      let subTask = contentData.tasks[t].subtasks[s]
      let subRoute = subTask.routeName
      let routePath = `/${parentRoute}/${subRoute}`
      let route = {
        path: routePath,
        component: ContentPage,
        meta: {
          requiredAuth: false,
          title: subTask.title ? `${subTask.title} - Return to Work Planner` : 'Return to Work Planner',
        },
        props: {
          subTask,
          parentTask,
          id: subTask.routeName,
        },
      }
      routeConfig.addRoute(route)
    }
  }

  store.dispatch('tasks/renderNextStepModal', false)
  window.scrollTo(0, 0)

  if (!auth && to.meta.requiredAuth) {
    console.log('Not authed - going to error')
    next({ name: 'Error' })
  } else {
    console.log('going to authed route')
    next()
  }
});

function pushMtmUserId() {
  const uid = store.getters['employer/getEmployerData']?.ID
  if (uid) {
    window._mtm = window._mtm || []
    if (!window._mtm.find(element => element['uid'])) {
      window._mtm.push({ 'uid': uid })
    }
  }
}
